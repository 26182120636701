
















import { Component, Vue } from "vue-property-decorator";
import { IAmount } from "@/core/types";

@Component
export default class BaseAmount extends Vue {
  get type(): "sell" | "buy" {
    return this.$route.path.includes("vender") ? "sell" : "buy";
  }
  get forexInfoRate(): IAmount {
    return this.$store.state[this.type].prices.forexInfo.rate;
  }
  get feeNinetyNine(): IAmount {
    return this.$store.state[this.type].prices.forexInfo.rateWithCommission;
  }

  tFormat(s: string): string {
    return (this.$t(s) as string).replaceAll("\\n", "<br />");
  }
  get helpSections(): {
    title: string;
    content: string;
  }[] {
    return [
      {
        title: this.tFormat("AssetDetail_HelpSection1_Title"),
        content: this.tFormat("AssetDetail_HelpSection1_Content"),
      },
      {
        title: this.tFormat("AssetDetail_HelpSection2_Title"),
        content: this.tFormat("AssetDetail_HelpSection2_Content"),
      },
      {
        title: this.tFormat("AssetDetail_HelpSection3_Title"),
        content: this.tFormat("AssetDetail_HelpSection3_Content"),
      },
      {
        title: this.tFormat("AssetDetail_HelpSection4_Title"),
        content: this.tFormat("AssetDetail_HelpSection4_Content"),
      },
      {
        title: this.tFormat("AssetDetail_HelpSection5_Title"),
        content: this.tFormat("AssetDetail_HelpSection5_Content"),
      },
      {
        title: this.tFormat("AssetDetail_HelpSection6_Title"),
        content: this.tFormat("AssetDetail_HelpSection6_Content"),
      },
    ];
  }
}
